import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { styled, Typography } from "@mui/material";
import { color } from "../Style/theme";
import { InnerWrapper, LandingPageElement } from "./components";

const JobBoardLinkButton = styled("a")(({ theme }) => ({
	display: "inline-block",
	padding: theme.spacing(2, 3),
	marginBottom: theme.spacing(3),
	backgroundColor: theme.palette.primary.medium,
	color: theme.palette.background.main,
	textDecoration: "none",
	fontWeight: "bold",
	borderRadius: "10px",
	transition: "background-color 0.3s ease",

	"&:hover": {
		backgroundColor: theme.palette.primary.light,
	},
}));

const ContactList = styled("ul")(({ theme }) => ({
	listStyle: "none",
	padding: 0,
	li: {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(2),
		color: theme.palette.primary.main,
		fontSize: "1.5em",
		marginBottom: theme.spacing(1),
	},
}));

function Contact() {
	return (
		<LandingPageElement name="contact" id="contact" light_bg="true">
			<InnerWrapper sx={{ padding: "2rem 0" }}>
				<Typography variant="h3" component="h2">
					Open Positions
				</Typography>
				<Typography variant="body1" sx={{ maxWidth: "900px" }}>
					We are always looking for talented individuals to join our team. Check
					out our open positions below:
				</Typography>
				<JobBoardLinkButton
					href="https://goalunit.notion.site/Exjobb-Praktik-f61007a5892e4c71b33f36487b9979b2?pvs=74"
					target="_blank"
					rel="noopener noreferrer"
				>
					Exjobb / Praktik - Stockholm
				</JobBoardLinkButton>
				<Typography variant="h3">
					Do you want to join <br />
					us or hire us?
				</Typography>
				<Typography variant="body1" sx={{ maxWidth: "900px" }}>
					We are always looking to get in contact with club representatives,
					skilled developers, and football fanatics. Don't hesitate to get in
					touch!
				</Typography>
				<ContactList>
					<li>
						<LocalPhoneIcon
							fontSize="large"
							sx={{ color: color.primary.light }}
						/>
						+46 708 333 136
					</li>
					<li>
						<MailOutlineIcon
							fontSize="large"
							sx={{ color: color.primary.light }}
						/>
						info@goalunit.com
					</li>
					<li>
						<LanguageIcon
							fontSize="large"
							sx={{ color: color.primary.light }}
						/>
						www.goalunit.com
					</li>
				</ContactList>
			</InnerWrapper>
		</LandingPageElement>
	);
}

export default Contact;
