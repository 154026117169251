import { React, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { exportPdfStore } from "../State/ExportPdfStore";
import "chart.js/auto";
import { Chart, registerables } from "chart.js";
import { marketValueComparisonDescription } from "../Util/ChartDescriptions";

Chart.register(...registerables);

const MarketValueComparison = (props) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const chartRef = useRef(null);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				type: "linear",
				display: true,
				position: "left",
				title: {
					display: true,
					text: "Market value (m €)",
				},
				ticks: {
					callback: function (value) {
						return value / 1000000;
					},
				},
			},
			yRight: {
				type: "linear",
				display: true,
				position: "right",
				title: {
					display: true,
					text: "Percent (%)",
				},
				grid: {
					drawOnChartArea: false,
				},
			},
		},
		plugins: {
			legend: {
				position: "top",
				labels: {
					padding: 30,
					font: {
						size: 16,
					},
				},
			},
		},
		animation: {
			onComplete: function () {
				if (chartRef.current) {
					const base64Image = chartRef.current.toBase64Image();
					exportPdfStore.setMarketValueComparisonData(base64Image);
				}
			},
		},
	};

	const loadData = (jsonData) => {
		var data = jsonData.filter((item) => item.SeasonName);
		data.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));

		const seasons = data.map((item) => item.SeasonName);
		const marketValues = data.map((item) => item.ClubMarketValueSum);
		const domesticPercentages = data.map((item) => item.DomesticPercentage);

		const backgroundcolors = [
			getComputedStyle(document.documentElement).getPropertyValue(
				"--element-color-1"
			),
			getComputedStyle(document.documentElement).getPropertyValue(
				"--element-color-3"
			),
		].map((color) => {
			const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
			return rgbaColor;
		});

		const datasets = [
			{
				type: "bar",
				label: "Market value",
				data: marketValues,
				yAxisID: "y",
				backgroundColor: backgroundcolors[0],
				order: 3,
			},
			{
				type: "line",
				label: "Percentage of league market value",
				data: domesticPercentages,
				yAxisID: "yRight",
				backgroundColor: backgroundcolors[1],
				borderColor: backgroundcolors[1],
				order: 2,
				tension: 0.4, // This enables Bezier curve interpolation
			},
		];
		setChartData({ labels: seasons, datasets: datasets });
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	return (
		<div className="chart-section">
			<div style={{ width: "65%", margin: "auto" }}>
				<Bar
					data={chartData}
					options={chartOptions}
					width={600}
					height={450}
					ref={chartRef}
				/>
			</div>
			<div className="description-section">
				<div className="description-container">
					<span style={{ fontWeight: "bold" }}>Description: </span> <br></br>
					{marketValueComparisonDescription}
				</div>
			</div>
		</div>
	);
};
export default MarketValueComparison;
