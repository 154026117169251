import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	IconButton,
	Modal,
	styled,
	Tooltip,
	Typography,
} from "@mui/material";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 1,
	borderRadius: "8px",
};

const CustomTypography = styled(Typography)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	color: "#000000",
	fontSize: "18px",
}));

const CustomButton = styled(Button)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	backgroundColor: "#1A5F7A",
	"&:hover": {
		backgroundColor: "#8B0000;",
	},
}));

const CancelSubscription = (userSubscription) => {
	const [open, setOpen] = useState(false);
	const [cancellationDate, setCancellationDate] = useState(false);
	const [subscription, setSubscription] = useState([]);
	const [disabled, setDisabled] = useState(false);
	const [planName, setPlanName] = useState([]);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		if (
			userSubscription &&
			userSubscription.userSubscription &&
			userSubscription.userSubscription.length > 0
		) {
			const subscription = userSubscription.userSubscription[0].Subscription;
			const trialEnd = subscription.trial_end * 1000;
			const currentTimestamp = Date.now();

			let CancelDate = new Date(subscription.current_term_end * 1000);

			if (trialEnd > currentTimestamp) {
				CancelDate = new Date(trialEnd);
			}

			const formattedDate = `${CancelDate.getDate().toString().padStart(2, "0")}/${(CancelDate.getMonth() + 1).toString().padStart(2, "0")}/${CancelDate.getFullYear()}`;
			setCancellationDate(formattedDate);
			setSubscription(subscription);
			setPlanName(userSubscription.userSubscription[0].Plan.external_name);

			if (subscription.cancelled_at > 0) {
				setDisabled(true);
			}
		} else {
			setDisabled(true);
		}
	}, [userSubscription]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const cancelActiveSubscription = async (subscriptionToCancel) => {
		try {
			const token = await getAccessTokenSilently();
			const header = { Authorization: `Bearer ${token}` };
			const body = JSON.stringify({ subscription: subscriptionToCancel });

			const subscriptionplans = await fetch("/api/cancel-subscription", {
				method: "POST",
				headers: header,
				credentials: "include",
				body: body,
			});

			if (!subscriptionplans.ok) {
				throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
			}
		} catch (error) {
			console.log("Something went wrong", error);
		}
		setOpen(false);
		setDisabled(true);
	};

	return (
		<Box>
			<CustomButton
				variant="contained"
				onClick={handleClickOpen}
				disabled={disabled}
			>
				Cancel Subscription
			</CustomButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="cancel-subscription-title"
			>
				<Box sx={modalStyle}>
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Tooltip title="Close">
							<IconButton
								onClick={handleClose}
								sx={{ "&:hover": { color: "#8B0000;" } }}
							>
								<CloseIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<CustomTypography
						id="cancel-subscription-title"
						sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
					>
						Cancel your subscription
					</CustomTypography>
					<Box sx={{ textAlign: "center", marginTop: 2 }}>
						<CustomTypography>
							Do you wish to cancel your{" "}
							<span style={{ fontWeight: "600" }}>{planName}</span>{" "}
							subscription?
						</CustomTypography>
						<CustomTypography>
							If you choose to do so, it'll take effect on{" "}
							<span style={{ fontWeight: "600" }}>{cancellationDate}</span>.
						</CustomTypography>
						<CustomTypography>
							You'll still be able to use Assistant Manager until then.
						</CustomTypography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "16px",
						}}
					>
						<CustomButton
							variant="contained"
							onClick={() => cancelActiveSubscription(subscription)}
							sx={{ backgroundColor: "#8B0000" }}
						>
							Cancel subscription
						</CustomButton>
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default CancelSubscription;
