import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	Modal,
	styled,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { auth0Service } from "../../Util/Auth0Client";

const CustomTypography = styled(Typography)(({ theme }) => ({
	textAlign: "center",
}));

const CustomButton = styled(Button)({
	backgroundColor: "#23374C",
	margin: 8,
	minWidth: 90,
	minHeight: 30,
	"&:hover": {
		backgroundColor: "#798DA8",
	},
});

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 1,
	borderRadius: "8px",
};

export function scrollToTop() {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
}

export function FeedbackForm({ isFormVisible, toggleForm }) {
	const [message, setMessage] = useState("");

	const closeForm = () => {
		toggleForm();
	};

	const handleSubmit = async () => {
		setMessage("");
		closeForm();

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			await fetch("/api/feedback", {
				method: "POST",
				headers: header,
				body: JSON.stringify({ message: message }),
			});
		} catch (error) {
			console.error("Error sending feedback:", error);
		}
	};

	return (
		<Modal open={isFormVisible} onClose={closeForm}>
			<Box sx={modalStyle}>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Tooltip title="Close">
						<IconButton
							onClick={closeForm}
							sx={{ "&:hover": { color: "#8B0000;" } }}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<CustomTypography>
					We strive to make the Assistant Manager as useful as possible for our
					customers.
				</CustomTypography>
				<CustomTypography>
					Feel free to send any thoughts and suggestions to us below!
				</CustomTypography>
				<FormControl fullWidth sx={{ marginTop: "16px", marginBottom: "16px" }}>
					<TextField
						id="message"
						label=""
						multiline
						rows={6}
						variant="outlined"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</FormControl>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CustomButton variant="contained" onClick={handleSubmit}>
						<p>Submit</p>
					</CustomButton>
				</Box>
			</Box>
		</Modal>
	);
}
