import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Button,
	CircularProgress,
	styled,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../Components/Table";
import { profileStore } from "../State/ProfileStore";
import { userEntitlementStore } from "../State/UserEntitlementStore";
import { routes } from "../Util/routes";

const CustomButton = styled(Button)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	backgroundColor: "#1A5F7A",
	"&:hover": {
		backgroundColor: "#23374C",
	},
}));

const BillingHistory = () => {
	const [tableData, setTableData] = useState([]);
	const [userHasSubscription, setUserHasSubscription] = useState(false);
	const [loading, setLoading] = useState(true);
	const { getAccessTokenSilently } = useAuth0();

	const [tableColumns] = useState([
		{ field: "PlanName", headerName: "Plan" },
		{ field: "status", headerName: "Status" },
		{ field: "total", headerName: "Total" },
		{ field: "due_date", headerName: "Due" },
		{ field: "paid_at", headerName: "Paid" },
		{ field: "id", headerName: "Invoice", isDownloadInvoice: true },
	]);

	useEffect(() => {
		if (Array.isArray(userEntitlementStore.entitlementData.Entitlements)) {
			if (userEntitlementStore.entitlementData.Entitlements.length > 0) {
				setUserHasSubscription(true);
			}
		}

		const fetchBillingHistory = async () => {
			try {
				const token = await getAccessTokenSilently();
				const header = { Authorization: `Bearer ${token}` };
				const response = await fetch("/api/billinghistory", {
					headers: header,
					credentials: "include",
				});
				const invoiceData = await response.json();

				const convertDateFields = (field, multiplier = 1000) => {
					if (field !== 0) {
						const date = new Date(field * multiplier);
						const day = String(date.getDate()).padStart(2, "0");
						const month = String(date.getMonth() + 1).padStart(2, "0");
						const year = date.getFullYear();
						return `${day}/${month}/${year}`;
					} else {
						return null;
					}
				};
				const convertCurrency = (amountField, currencyCode) => {
					if (amountField !== 0) {
						const currencySymbol = currencyCode === "EUR" ? "€" : "";
						return `${currencySymbol} ${(amountField / 100).toFixed(2)}`;
					} else {
						return null;
					}
				};

				invoiceData.forEach((invoice) => {
					invoice.invoice.amount_paid = convertCurrency(
						invoice.invoice.amount_paid,
						invoice.invoice.currency_code
					);
					invoice.invoice.total = convertCurrency(
						invoice.invoice.total,
						invoice.invoice.currency_code
					);
					invoice.invoice.credits_applied = convertCurrency(
						invoice.invoice.credits_applied,
						invoice.invoice.currency_code
					);

					invoice.invoice.date = convertDateFields(invoice.invoice.date);
					invoice.invoice.due_date = convertDateFields(
						invoice.invoice.due_date
					);
					invoice.invoice.paid_at = convertDateFields(invoice.invoice.paid_at);
				});

				let flattenedData = invoiceData.map((row) => {
					const PlanName = row.invoice.line_items[0].description;
					return {
						PlanName,
						...row.invoice,
					};
				});
				console.log();
				setTableData(flattenedData);
			} catch (error) {
				console.log("Something went wrong", error);
			} finally {
				setLoading(false);
			}
		};
		fetchBillingHistory();
	}, []);

	const navigate = useNavigate();
	const handleButtonClick = () => {
		profileStore.setCurrentTabIndex(0);
		navigate(routes.am.profile);
	};

	if (loading) {
		return <CircularProgress />;
	}

	return (
		<div>
			{tableData.length > 0 ? (
				<div>
					<CustomTable TableData={tableData} TableColumns={tableColumns} />
				</div>
			) : (
				<Box sx={{ textAlign: "center" }}>
					<Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
						Billing History
					</Typography>
					{userHasSubscription ? (
						<Typography variant="h6" gutterBottom sx={{ mb: 5 }}>
							No invoice generated yet. <br />
							Check back in a couple of weeks.
						</Typography>
					) : (
						<>
							<Typography variant="h6" gutterBottom sx={{ mb: 5 }}>
								You currently don't have an active subscription.
								<br />
								Please click the button below to subscribe.
							</Typography>
							<CustomButton variant="contained" onClick={handleButtonClick}>
								Create Subscription
							</CustomButton>
						</>
					)}
				</Box>
			)}
		</div>
	);
};
export default BillingHistory;
