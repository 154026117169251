import { createTheme } from "@mui/material";

export const breakpoints = {
	xs: 0, // Extra-small screens
	sm: 576, // Small screens (mobile)
	md: 768, // Medium screens (tablets)
	lg: 992, // Large screens (small laptops)
	xl: 1200, // Extra-large screens (desktops)
	xxl: 1440, // Extra-extra-large screens (large desktops)
	xxxl: 2048, // Wide screen desktops and TVs
};

export const color = {
	primary: {
		main: "#23374C",
		medium: "#5a6b83",
		light: "#798DA8",
		contrast: "#ffffff",
	},
	secondary: {
		main: "#f3d250",
	},
	tertiary: {
		main: "#936b6a",
	},
	background: {
		main: "#F3F5F1",
		light: "#f8f8f8",
	},
};

const theme = createTheme({
	palette: {
		...color,
	},
	typography: {
		fontFamily: "Montserrat, sans-serif",
		color: color.primary.main,
		body1: {
			margin: "1em 0",
			color: color.primary.main,
			fontSize: "1rem",
			["@media (min-width:" + breakpoints.md + "px)"]: {
				fontSize: "1.15rem",
			},
			["@media (min-width:" + breakpoints.xl + "px)"]: {
				fontSize: "1.3rem",
			},
		},
		// There should only be one (!) h1 element per page
		h1: {
			fontWeight: 700,
			fontSize: "2.5rem",
			margin: "0.67em 0",
			["@media (min-width:" + breakpoints.md + "px)"]: {
				fontSize: "3.5rem",
			},
			["@media (min-width:" + breakpoints.xxxl + "px)"]: {
				fontSize: "5rem",
			},
		},
		h2: {
			fontWeight: 700,
			color: color.primary.main,
			textAlign: "center",
			fontSize: "2rem",
			margin: "0.83em 0",
			["@media (min-width:" + breakpoints.md + "px)"]: {
				fontSize: "3rem",
			},
			["@media (min-width:" + breakpoints.xxxl + "px)"]: {
				fontSize: "4.5rem",
			},
		},
		h3: {
			fontWeight: 700,
			color: color.primary.main,
			fontSize: "1.2rem",
			margin: "1em 0",
			["@media (min-width:" + breakpoints.md + "px)"]: {
				fontSize: "1.5rem",
			},
			["@media (min-width:" + breakpoints.xxxl + "px)"]: {
				fontSize: "2rem",
			},
		},
		h4: {
			fontWeight: 700,
			color: color.primary.main,
			margin: "1.33em 0",
		},
	},
	breakpoints: {
		values: {
			...breakpoints,
		},
	},
	spacing: 8,
});

export default theme;
