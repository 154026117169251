import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { exportPdfStore } from "../State/ExportPdfStore";
import { powerRankDescrption } from "../Util/ChartDescriptions";
import "../Style/style.css";

Chart.register(...registerables, annotationPlugin);

const Powerrank = (props) => {
	const chartRef = useRef(null);

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});
	const [maxYvalueForGraph, setMaxYvalueForGraph] = useState(0);

	const backgroundcolors = [
		getComputedStyle(document.documentElement).getPropertyValue(
			"--element-color-3"
		),
	].map((color) => {
		const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
		return rgbaColor;
	});

	const loadData = (data) => {
		const sortedData = data.filter((item) => item.SeasonName);
		const processedData = sortedData.sort((a, b) => {
			const [startYearA, endYearA] = a.SeasonName.split("/").map(Number);
			const [startYearB, endYearB] = b.SeasonName.split("/").map(Number);

			// Compare the start years first
			if (startYearA !== startYearB) {
				return startYearA - startYearB;
			}

			// If start years are the same, compare the end years
			return endYearA - endYearB;
		});

		const labels = processedData.map((item) => item.SeasonName);
		const datasets = [
			{
				label: "Rank: ",
				data: processedData.map((PR) => PR.PowerRank),
				borderColor: backgroundcolors[0],
				backgroundColor: backgroundcolors[0],
				tension: 0.4, // This enables Bezier curve interpolation
			},
		];

		const yValue = Math.max(...processedData.map((PR) => PR.PowerRank)) * 2;
		setMaxYvalueForGraph(yValue);
		setChartData({ labels, datasets });
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: true,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				min: 1,
				max: maxYvalueForGraph,
				reverse: true,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
		animation: {
			onComplete: function () {
				if (chartRef.current) {
					const base64Image = chartRef.current.toBase64Image();
					exportPdfStore.setPowerrankData(base64Image);
				}
			},
		},
	};

	return (
		<div className="chart-section">
			<div style={{ width: "65%", margin: "auto" }}>
				<Line
					data={chartData}
					options={chartOptions}
					width={600}
					height={500}
					ref={chartRef}
				/>
			</div>
			<div className="description-section">
				<div className="description-container">
					<span style={{ fontWeight: "bold" }}>Description: </span> <br></br>
					{powerRankDescrption}
				</div>
			</div>
		</div>
	);
};

export default Powerrank;
