import React from "react";
import {
	Autocomplete,
	CircularProgress,
	styled,
	TextField,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { impersonationStore } from "../State/ImpersonationStore";

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
	width: 300,
	"& .MuiAutocomplete-root": {
		fontSize: "20px",
		fontFamily: "Montserrat, sans-serif",
	},
	"& .MuiAutocomplete-inputRoot": {
		fontSize: "20px",
		fontFamily: "Montserrat, sans-serif",
	},
	"& .MuiAutocomplete-listbox": {
		fontSize: "20px",
		fontFamily: "Montserrat, sans-serif",
	},

	[theme.breakpoints.down("md")]: {
		width: 250,
	},

	[theme.breakpoints.down("sm")]: {
		width: 200,
		"& .MuiInputBase-root": {
			padding: "0 5px",
		},
	},
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	"& .MuiInputLabel-root": {
		fontSize: "20px",
		fontFamily: "Montserrat, sans-serif",

		[theme.breakpoints.down("lg")]: {
			fontSize: "18px",
		},
	},
	"& .MuiInputBase-input": {
		fontSize: "20px",
		fontFamily: "Montserrat, sans-serif",

		[theme.breakpoints.down("lg")]: {
			fontSize: "18px",
		},
	},
}));

const Impersonation = observer(() => {
	const filterOptions = (options, state) => {
		const inputValue = state.inputValue.toLowerCase();
		return options.filter(
			(option) =>
				option.ClubName.toLowerCase().includes(inputValue) ||
				option.DivisionName.toLowerCase().includes(inputValue)
		);
	};

	if (impersonationStore.isLoading) {
		return <CircularProgress />;
	}

	if (impersonationStore.error) {
		return <div>{impersonationStore.error}</div>;
	}

	return (
		<>
			<CustomAutocomplete
				id="impersonation"
				options={impersonationStore.dropdownOptions}
				disableClearable
				groupBy={(option) => option.DivisionName}
				getOptionLabel={(option) => option.ClubName}
				onChange={impersonationStore.handleChange}
				value={impersonationStore.selectedOption}
				filterOptions={filterOptions}
				renderInput={(params) => <StyledTextField {...params} label="Club" />}
				renderGroup={(params) => (
					<li key={params.key}>
						<div>{params.group}</div>
						<ul>{params.children}</ul>
					</li>
				)}
			/>
		</>
	);
});

export default Impersonation;
