import React, { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import CustomTable from "../Components/Table";
import { exportPdfStore } from "../State/ExportPdfStore";
import { transferKpiRiskDescription } from "../Util/ChartDescriptions";
import "../Style/am_style.css";
import "./Datapage.css";
import "./Dashboard.css";

const TransferKPIRisk = (props) => {
	const [playerData, setPlayerData] = useState([]);
	const [transferKPIRiskLevel, setTransferKPIRiskLevel] = useState([]);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});

	const [tableColumns] = useState([
		{ field: "PlayerName", headerName: "Player" },
		{ field: "NewTransferKpi", headerName: "Transfer KPI" },
		{ field: "PartOfTeamKPI", headerName: "Team Share" },
	]);

	const chartRef = useRef(null);

	const options = {
		plugins: {
			legend: {
				labels: {
					font: {
						size: 15,
					},
				},
			},
			tooltip: {
				callbacks: {
					label: function (tooltipItem) {
						return `${tooltipItem.label}: ${tooltipItem.raw}%`;
					},
				},
			},
		},
		animation: {
			onComplete: function () {
				if (chartRef.current) {
					const base64Image = chartRef.current.toBase64Image();
					exportPdfStore.setTransferKPIRiskData(base64Image);
				}
			},
		},
	};

	const generateRandomColor = () => {
		const r = Math.floor(Math.random() * 256);
		const g = Math.floor(Math.random() * 256);
		const b = Math.floor(Math.random() * 256);
		return `rgba(${r},${g},${b},0.5)`;
	};

	const generateTransferRiskLevel = (data) => {
		const numberOfRows = data.length;
		let transferLevel;

		if (numberOfRows > 4) {
			transferLevel = "Low";
		} else if (numberOfRows >= 3 && numberOfRows <= 4) {
			transferLevel = "Medium";
		} else if (numberOfRows < 3 && numberOfRows !== 0) {
			transferLevel = "High";
		} else {
			transferLevel = "Not available";
		}

		return transferLevel;
	};

	useEffect(() => {
		const loadData = (data, comparisonData) => {
			const predefinedColors = [
				getComputedStyle(document.documentElement).getPropertyValue(
					"--element-color-1"
				),
				getComputedStyle(document.documentElement).getPropertyValue(
					"--element-color-2"
				),
				getComputedStyle(document.documentElement).getPropertyValue(
					"--element-color-3"
				),
				getComputedStyle(document.documentElement).getPropertyValue(
					"--element-color-4"
				),
			].map((color) => {
				const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
				return rgbaColor;
			});

			setTransferKPIRiskLevel(generateTransferRiskLevel(data));

			var filteredData = comparisonData.filter((item) => item.SeasonName);
			const sortedCdata = filteredData.sort((a, b) =>
				b.SeasonName.localeCompare(a.SeasonName)
			);
			const lastSeasonTeamKPI = sortedCdata[0].NewTeamTransferKPI;
			const sortedData = data.sort(
				(a, b) => b.NewTransferKpi - a.NewTransferKpi
			);
			const totalKPIRiskValue = data.reduce(
				(acc, d) => acc + d.NewTransferKpi,
				0
			);
			const partOfTeamKPI = playerData.map((d) =>
				((d.NewTransferKpi / lastSeasonTeamKPI) * 100).toFixed(2)
			);

			playerData.forEach((d) => {
				if (typeof d.NewTransferKpi === "number") {
					d.NewTransferKpi = parseFloat(d.NewTransferKpi.toFixed(2));
				}
			});

			sortedData.forEach((d, i) => {
				d.PartOfTeamKPI = partOfTeamKPI[i];
			});

			setPlayerData(sortedData);

			const totalDataLength = sortedData.length + 1;
			const requiredColors =
				totalDataLength > predefinedColors.length
					? [
							...predefinedColors,
							...Array(totalDataLength - predefinedColors.length)
								.fill()
								.map(generateRandomColor),
						]
					: predefinedColors;

			setChartData({
				labels: ["Rest of the team", ...sortedData.map((d) => d.PlayerName)],
				datasets: [
					{
						data: [
							(
								((lastSeasonTeamKPI - totalKPIRiskValue) / lastSeasonTeamKPI) *
								100
							).toFixed(2),
							...sortedData.map((d) => d.PartOfTeamKPI),
						],
						backgroundColor: requiredColors,
					},
				],
			});
		};
		loadData(props.data, props.comparisonData);
	}, [props.data, props.comparisonData, playerData]);

	if (!props.data || !props.data.some((item) => item.PlayerName)) {
		return (
			<div className="chart-section">
				<div className="dashboard-KPI-card" style={{ pointerEvents: "none" }}>
					<div className="beta-tag">Beta</div>
					<div className="dashboard-KPI-card-title">Transfer KPI Risk</div>
					<div className="dashboard-KPI-card-value">
						<span
							style={{
								color:
									transferKPIRiskLevel === "High"
										? "red"
										: transferKPIRiskLevel === "Medium"
											? "orange"
											: "green",
							}}
						>
							{transferKPIRiskLevel ? transferKPIRiskLevel : "Not available"}
						</span>
					</div>
				</div>

				<div className="description-section">
					<div className="description-container">
						<span style={{ fontWeight: "bold", width: "100%" }}>
							Description:{" "}
						</span>
						<br />
						{transferKpiRiskDescription}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="chart-section">
			<div style={{ width: "65%", margin: "auto" }}>
				<Pie
					data={chartData}
					options={options}
					width={200}
					height={200}
					ref={chartRef}
				/>
			</div>
			<div style={{ width: "50%", margin: "auto" }}>
				<CustomTable TableData={playerData} TableColumns={tableColumns} />
			</div>
			<div className="description-section">
				<div className="description-container">
					<span style={{ fontWeight: "bold", width: "100%" }}>
						Description:{" "}
					</span>
					<br />
					{transferKpiRiskDescription}
				</div>
			</div>
		</div>
	);
};
export default TransferKPIRisk;
