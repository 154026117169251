import React, { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import "chart.js/auto";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import regression from "regression";
import CustomSlider from "../Components/CustomSlider";
import FloatingButton from "../Components/FloatingButton/FloatingButton";
import { scrollToTop } from "../Components/FloatingButton/utilFunctions";
import Sidebar from "../Components/SideBar/SideBar";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	fontSize: "20px",
	marginTop: "10px",
	fontFamily: "Montserrat, sans-serif",
	color: "#23374C",
	"&.Mui-selected": {
		color: "#23374C",
	},
	"&:hover": {
		color: "#23374C",
	},
}));

const generateChartOptions = (useLeagueData) => ({
	scales: {
		x: {
			title: {
				display: true,
				text: "Financial size (in millions)",
			},
			type: "logarithmic",
			position: "bottom",
			ticks: {
				callback: function (value) {
					return (value / 1000000).toFixed(1);
				},
				maxTicksLimit: 8,
				autoSkip: true,
				maxRotation: 45,
				minRotation: 45,
			},
		},
		y: {
			title: {
				display: true,
				text: "Total Coefficient",
			},
		},
	},
	plugins: {
		legend: {
			display: true,
			labels: {
				filter: (legendItem) => legendItem.text,
				usePointStyle: true,
				padding: 20,
				boxHeight: 9,
				pointStyle: "circle",
			},
		},
		title: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					const isConfirmed = context.raw.calcMethod === "existing";
					return [
						`Season: ${context.raw.season}`,
						`Club: ${context.raw.club}`,
						`${isConfirmed ? "" : "Estimated"} Financial size:  ${(context.raw.x / 1000000).toFixed(2)}M`,
						`Total Coefficient: ${context.raw.y.toFixed(3)}`,
					];
				},
			},
		},
	},
	animation: {
		duration: 0,
	},
	responsive: true,
	maintainAspectRatio: false,
});

const processScatterData = (
	data,
	useLeagueData,
	sessionClubId,
	sliderValue,
	useFirstDivisonOnly,
	setDataArrayLen,
	setSliderValue,
	setAvailableClubs
) => {
	const scatterData = data.map((KPIF) => {
		const seasonYear = parseInt(KPIF.SeasonName, 10);
		const startYear = seasonYear - 1;
		const endYear = seasonYear + 1;

		// Format the years to only show the last two digits
		let seasonRange = `${startYear}-${endYear}`;

		if (!KPIF.SpringAutumn) {
			const startYearShort = startYear.toString().slice(-2);
			const endYearShort = endYear.toString().slice(-2);

			// Format the range as '19/20 - 21/22'
			seasonRange = `${startYearShort}/${seasonYear.toString().slice(-2)} - ${endYearShort}/${(endYear + 1).toString().slice(-2)}`;
		}

		return {
			x: KPIF.RollingFinancialAvg,
			y: KPIF.RollingTotalCoefficient,
			season: seasonRange,
			club: KPIF.ClubName,
			clubId: KPIF.ClubId,
			division: KPIF.DivisionLevel,
			calcMethod: KPIF.CalculationMethod,
		};
	});

	let filteredData;
	let regressionData;
	if (useLeagueData) {
		filteredData = scatterData;
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
		regressionData = filteredData;
	} else if (useFirstDivisonOnly) {
		const divisonData = scatterData.filter((KPIF) => KPIF.division < 2);
		regressionData = divisonData;
		setDataArrayLen(divisonData.length);
		setSliderValue((prevValue) =>
			prevValue !== undefined ? prevValue : Math.floor(divisonData.length / 2)
		); // Set slider default if undefined
		filteredData = divisonData.slice(0, sliderValue);
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
	} else {
		setDataArrayLen(scatterData.length);
		setSliderValue((prevValue) =>
			prevValue !== undefined ? prevValue : Math.floor(scatterData.length / 2)
		); // Set slider default if undefined
		regressionData = scatterData;
		filteredData = scatterData.slice(0, sliderValue);
		const clubs = Array.from(
			new Set(
				filteredData
					.filter((KPIF) => KPIF.clubId !== sessionClubId)
					.map((KPIF) => KPIF.club)
			)
		);
		setAvailableClubs(clubs);
	}

	// TODO: changes regression, the logarithmic regression is not defindined for non-positive integers
	const regressionResult = regression.logarithmic(
		regressionData
			.filter((point) => point.x > 0)
			.map((point) => [point.x, point.y])
	);

	// make the regression line expand a bit beyond the points
	const maxX = Math.max(...filteredData.map((point) => point.x)) * 1.02;
	const minX =
		Math.min(
			...filteredData.filter((point) => point.x > 0).map((point) => point.x)
		) * 0.98;

	const regressionLine = [
		{ x: minX, y: regressionResult.predict(minX)[1] },
		{ x: maxX, y: regressionResult.predict(maxX)[1] },
	];

	const sessionClubName = filteredData.find(
		(point) => point.clubId === sessionClubId
	)?.club;

	return {
		scatterData: filteredData,
		regressionLine,
		sessionClubName,
	};
};

const updateChartData = (
	selectedClubs,
	sessionClubId,
	scatterData,
	regressionLine,
	sessionClubName,
	leagueData
) => {
	const backgroundColors = [
		getComputedStyle(document.documentElement).getPropertyValue(
			"--element-color-1"
		),
		getComputedStyle(document.documentElement).getPropertyValue(
			"--element-color-2"
		),
		getComputedStyle(document.documentElement).getPropertyValue(
			"--element-color-3"
		),
		getComputedStyle(document.documentElement).getPropertyValue(
			"--element-color-4"
		),
	].map((color) => {
		const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
		return rgbaColor;
	});

	// generates the same color based on a string
	const generateColorFromString = (str) => {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let color = "#";
		for (let i = 0; i < 3; i++) {
			const value = (hash >> (i * 8)) & 0xff;
			color += ("00" + value.toString(16)).substr(-2);
		}
		return color;
	};

	const specificClubData = scatterData.filter(
		(point) => point.clubId === sessionClubId
	);

	const leagueScatterData = scatterData.filter(
		(point) =>
			leagueData.some((leaguePoint) => leaguePoint.ClubId === point.clubId) &&
			point.clubId !== sessionClubId &&
			!selectedClubs.some((club) => point.club === club)
	);

	const filteredScatterData = scatterData.filter(
		(point) => !selectedClubs.includes(point.club)
	);

	const filteredScatterDataWithOutLeague = filteredScatterData.filter(
		(point) =>
			!leagueData.some((leaguePoint) => leaguePoint.ClubId === point.clubId) &&
			point.clubId !== sessionClubId
	);

	const dataset = selectedClubs.map((club, index) => {
		const clubData = scatterData.filter((point) => point.club === club);
		return {
			type: "scatter",
			data: clubData,
			backgroundColor: generateColorFromString(club),
			borderColor: generateColorFromString(club),
			pointRadius: 6,
			pointStyle: clubData.map((point) =>
				point.calcMethod === "existing" ? "circle" : "triangle"
			),
		};
	});

	return {
		datasets: [
			...dataset,
			{
				type: "scatter",
				label: sessionClubName,
				data: specificClubData,
				backgroundColor: backgroundColors[1],
				borderColor: backgroundColors[1],
				pointBackgroundColor: "rgb(34,139,34)",
				pointRadius: 7,
				pointStyle: specificClubData.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},
			{
				type: "scatter",
				label:
					filteredScatterDataWithOutLeague.length !== 0
						? "Other Teams"
						: undefined,
				data: filteredScatterDataWithOutLeague,
				backgroundColor: backgroundColors[1],
				borderColor: backgroundColors[1],
				pointBackgroundColor: backgroundColors[0],
				pointRadius: 5,
				pointStyle: filteredScatterDataWithOutLeague.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},
			{
				type: "scatter",
				label: "League Teams",
				data: leagueScatterData,
				backgroundColor: backgroundColors[3],
				borderColor: backgroundColors[3],
				pointRadius: 5,
				pointStyle: leagueScatterData.map((point) =>
					point.calcMethod === "existing" ? "circle" : "triangle"
				),
			},

			{
				type: "line",
				label: "Expected performance",
				data: regressionLine,
				fill: false,
				borderColor: "rgba(220, 53, 69, 1)",
				backgroundColor: "rgba(220, 53, 69, 1)",
				borderWidth: 2,
				pointRadius: 0,
			},
		],
	};
};

const PerformanceComparison = (props) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const [marks] = useState([
		{ value: 50, label: 50 },
		{ value: 100, label: 100 },
		{ value: 150, label: 150 },
		{ value: 200, label: 200 },
		{ value: 250, label: 250 },
		{ value: 300, label: 300 },
	]);
	const [sliderValue, setSliderValue] = useState();
	const [sliderLen, setSliderLen] = useState(0);
	const [useLeagueData, setUseLeagueData] = useState(true);
	const [tabValue, setTabValue] = useState("league");
	const [useFirstDivisonOnly, setUseFirstDivisonOnly] = useState(false);
	const [availableClubs, setAvailableClubs] = useState([]);
	const [selectedClubs, setSelectedClubs] = useState([]);

	useEffect(() => {
		const loadData = async () => {
			setUseLeagueData(tabValue === "league");

			const selectedData = useLeagueData ? props.leagueData : props.data;

			const { scatterData, regressionLine, sessionClubName } =
				processScatterData(
					selectedData,
					useLeagueData,
					props.sessionClubId,
					sliderValue,
					useFirstDivisonOnly,
					setSliderLen,
					setSliderValue,
					setAvailableClubs
				);
			const chartData = updateChartData(
				selectedClubs,
				props.sessionClubId,
				scatterData,
				regressionLine,
				sessionClubName,
				props.leagueData
			);
			setChartData(chartData);
		};

		loadData();
	}, [
		props.leagueData,
		props.data,
		props.sessionClubId,
		sliderValue,
		useFirstDivisonOnly,
		selectedClubs,
		tabValue,
		useLeagueData,
	]);

	const handleToggle = (event, newTabValue) => {
		if (newTabValue !== null) {
			setTabValue(newTabValue);
		}
	};

	return (
		<div className="chart-section">
			<div style={{ flex: "1 1 60%" }}>
				<ToggleButtonGroup
					value={tabValue}
					exclusive
					onChange={handleToggle}
					aria-label="Platform"
					sx={{ display: "flex", justifyContent: "center", mb: 2 }}
				>
					<CustomToggleButton sx={{ padding: 2 }} value="league">
						League
					</CustomToggleButton>
					<CustomToggleButton sx={{ padding: 2 }} value="global">
						Global
					</CustomToggleButton>
				</ToggleButtonGroup>
				<div style={{ position: "relative" }}>
					<div>
						<Scatter
							data={chartData}
							options={generateChartOptions(useLeagueData)}
							width={700}
							height={700}
						/>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{!useLeagueData && (
						<>
							<span style={{ fontWeight: "bold" }}>Number of teams</span>
							<CustomSlider
								aria-label="Teams"
								onChange={(event, value) => setSliderValue(value)}
								value={sliderValue || Math.floor(sliderLen / 2)}
								valueLabelDisplay="auto"
								defaultValue={Math.floor(sliderLen / 2)}
								step={1}
								marks={marks}
								min={1}
								max={sliderLen}
							/>
							<FormControlLabel
								control={
									<Switch
										checked={useFirstDivisonOnly}
										onChange={() =>
											setUseFirstDivisonOnly((prevState) => !prevState)
										}
									/>
								}
								label="Only teams playing in first division"
								style={{
									fontWeight: "bold",
									marginLeft: "10px",
									marginBottom: "20px",
								}}
							/>
						</>
					)}
				</div>
			</div>
			<div>
				<Sidebar
					availableClubs={availableClubs}
					selectedClubs={selectedClubs}
					setSelectedClubs={setSelectedClubs}
				/>
				<div className="description-container" style={{ marginTop: "10vh" }}>
					<span style={{ fontWeight: "bold" }}>Description</span>
					<br />
					The graph shows financial size (mEUR, average 3 years) on the X-axis
					and Power rank coefficient (Average, 3 years) on the Y-axis. Teams
					under the red trend line have been underperforming as compared to
					their financial size, and teams above the red trendline have been
					overperforming as compared to their financial size.
					<br></br>
					<br></br>
					<span style={{ fontWeight: "bold", fontSize: "16px" }}>Note: </span>
					<br></br>
					<div style={{ alignItems: "left" }}>
						<ul style={{ listStyle: "none", padding: 0 }}>
							<li>
								<span style={{ fontWeight: "bold" }}>
									<RadioButtonUncheckedIcon /> Circle:
								</span>{" "}
								Confirmed Value
							</li>
							<li>
								<span style={{ fontWeight: "bold" }}>
									<ChangeHistoryIcon /> Triangle:
								</span>{" "}
								Estimated Value
							</li>
						</ul>
					</div>
				</div>
			</div>
			<FloatingButton
				onClickAction={scrollToTop}
				isVisible={false}
				iconName={ArrowUpwardIcon}
			/>
		</div>
	);
};

export default PerformanceComparison;
