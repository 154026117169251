import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { routes } from "../Util/routes";
import { InnerWrapper, LandingPageElement } from "./components";

const BigButton = styled(Button)(({ theme }) => ({
	padding: theme.spacing(2, 4),
	marginTop: theme.spacing(4),
	fontSize: "1rem",
	fontWeight: "bold",
	color: theme.palette.background.light,
	backgroundColor: theme.palette.primary.medium,
	textDecoration: "none",
	borderRadius: "10px",
	transition: "background-color 0.5s ease",

	"&:hover": {
		backgroundColor: theme.palette.primary.light,
	},

	[theme.breakpoints.up("md")]: {
		padding: theme.spacing(4, 8),
		fontSize: "1.5rem",
	},

	[theme.breakpoints.up("xxxl")]: {
		padding: theme.spacing(6, 12),
		fontSize: "2rem",
	},
}));

function AM() {
	const { isAuthenticated, loginWithRedirect } = useAuth0();

	return (
		<LandingPageElement name="am" id="am" light_bg="true">
			<InnerWrapper sx={{ textAlign: "center" }}>
				<Typography variant="h2">Assistant Manager</Typography>
				{isAuthenticated ? (
					<Link to={routes.am.clubSummary}>
						<BigButton>Open Assistant Manager</BigButton>
					</Link>
				) : (
					<BigButton onClick={() => loginWithRedirect()}>
						Log In / Sign up
					</BigButton>
				)}
			</InnerWrapper>
		</LandingPageElement>
	);
}

export default AM;
