import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Menu, MenuItem, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import theme from "../../Style/theme";

const mobileMenuLinkStyles = {
	textDecoration: "none",
	whiteSpace: "nowrap",
	color: theme.palette.primary.main,
	flex: 1,
	"&:hover, &.active": {
		color: theme.palette.primary.light,
	},
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
	...mobileMenuLinkStyles,
}));

const StyledScrollLink = styled(ScrollLink)(({ theme }) => ({
	...mobileMenuLinkStyles,
}));

const MobileMenuBtn = styled(Button)(({ theme, hidefrom }) => ({
	marginLeft: "20px",
	[theme.breakpoints.down("md")]: {
		minWidth: "40px",
	},
	[theme.breakpoints.down("sm")]: {
		minWidth: "30px",
	},
	[theme.breakpoints.up(hidefrom)]: {
		display: "none",
	},
}));

const MobileMenuItem = styled(MenuItem)(({ theme }) => ({
	display: "flex",
	textDecorationLine: "none",
	fontFamily: theme.typography.fontFamily,
	fontSize: "18px",
	fontWeight: "bold",
	whiteSpace: "nowrap",
	padding: "10px 16px",
	color: theme.palette.primary.main,
	cursor: "pointer",
	margin: 0,
}));

const MobileMenu = ({ menuItems, hideFrom }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const openMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<MobileMenuBtn onClick={openMenu} hidefrom={hideFrom}>
				<MenuIcon />
			</MobileMenuBtn>
			<Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
				{menuItems.map((item, i) => (
					<MobileMenuItem key={i}>
						{item.type === "scroll" ? (
							<StyledScrollLink
								key={i}
								to={item.to}
								smooth={true}
								duration={500}
								hashSpy={true}
								offset={item.offset}
								onClick={handleCloseMenu}
							>
								{item.label}
							</StyledScrollLink>
						) : (
							<StyledNavLink
								key={i}
								to={item.to}
								onClick={() => {
									handleCloseMenu();
									item.onClick && item.onClick();
								}}
							>
								{item.label}
							</StyledNavLink>
						)}
					</MobileMenuItem>
				))}
			</Menu>
		</>
	);
};

export default MobileMenu;
