import React, { memo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Telegram from "@mui/icons-material/Telegram";
import { Box, styled, Tab, Tabs, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import BillingHistory from "../Chargebee/BillingHistory";
import SubscriptionPlans from "../Chargebee/SubscriptionPlans";
import FloatingButton from "../Components/FloatingButton/FloatingButton";
import { FeedbackForm } from "../Components/FloatingButton/utilFunctions";
import { profileStore } from "../State/ProfileStore";
import PasswordResetForm from "./ResetPassword";

const CustomTab = styled(Tab)(({ theme }) => ({
	color: "#4A6877",
	fontFamily: "Montserrat, sans-serif",
	fontWeight: 600,
	fontSize: "14px",
	textTransform: "none",
	"&.Mui-selected": {
		color: "#23374C",
	},
	"&.Mui-disabled": {
		color: "#A9A9A9",
	},
	"&:hover": {
		color: "#23374C",
		opacity: 1,
	},
}));

const TabPanel = memo(function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
			style={{
				display: value === index ? "block" : "none",
				width: "100%",
				height: "100%",
				paddingTop: "50px",
				backgroundColor: "#F3F5F1",
			}}
		>
			{value === index && (
				<Box
					sx={{
						p: 3,
						display: "flex",
						justifyContent: "center",
						height: "100%",
					}}
				>
					{children}
				</Box>
			)}
		</div>
	);
});

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const Profile = observer(() => {
	const { isLoading } = useAuth0();

	const [isFormVisible, setIsFormVisible] = useState(false); // Add state to manage form visibility

	// Function to toggle the feedback form's visibility
	const toggleForm = () => {
		setIsFormVisible((prev) => !prev);
	};

	if (isLoading) {
		return <div>Loading ...</div>;
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					height: "100%", // Full viewport height
					backgroundColor: "#F3F5F1",
				}}
			>
				<Tabs
					orientation="vertical"
					aria-label="basic tabs example"
					value={profileStore.currentTabIndex}
					onChange={profileStore.handleTabChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "#23374C", // Replace with your desired color
							width: "3px", // Adjust the width of the indicator as needed
						},
					}}
					sx={{
						borderRight: 1,
						borderColor: "divider",
						width: "200px", // Set a fixed width for the Tabs
					}}
				>
					<CustomTab label="My subscription" {...a11yProps(0)} />
					<CustomTab label="Change Login details" {...a11yProps(1)} />
					<CustomTab label="Billing history" {...a11yProps(2)} />
				</Tabs>
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						padding: "20px",
					}}
				>
					<TabPanel value={profileStore.currentTabIndex} index={0}>
						<SubscriptionPlans />
					</TabPanel>
					<TabPanel value={profileStore.currentTabIndex} index={1}>
						<PasswordResetForm />
					</TabPanel>
					<TabPanel value={profileStore.currentTabIndex} index={2}>
						<BillingHistory />
					</TabPanel>
				</Box>
			</Box>
			<Tooltip title="Submit feedback">
				<FloatingButton
					onClickAction={toggleForm}
					isVisible={true}
					iconName={Telegram}
					toolTipText={"Submit feedback!"}
				/>
			</Tooltip>
			<FeedbackForm isFormVisible={isFormVisible} toggleForm={toggleForm} />
		</>
	);
});

export default Profile;
