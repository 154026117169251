import React from "react";
import { Chart, registerables } from "chart.js";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthProviderWithHistory from "./Authorization/AuthProviderWithHistory";
import customWatermarkPlugin from "./Components/Watermark";
import reportWebVitals from "./reportWebVitals";

Chart.register(...registerables, customWatermarkPlugin);

Chart.defaults.font.family = "Montserrat, sans-serif";
Chart.defaults.font.size = 24;
Chart.defaults.font.weight = 400;
Chart.defaults.plugins.tooltip.callbacks.labelFont = function (context) {
	return {
		size: 15,
		family: "Montserrat, sans-serif",
		weight: "400",
	};
};


const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<BrowserRouter>
		<AuthProviderWithHistory>
			<App />
		</AuthProviderWithHistory>
	</BrowserRouter>
);

reportWebVitals();
