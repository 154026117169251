import React, { useEffect, useRef, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import { Chart, registerables } from "chart.js";
import { Scatter } from "react-chartjs-2";
import "chart.js/auto";
import CustomTable from "../Components/Table";
import { exportPdfStore } from "../State/ExportPdfStore";
import {
	marketValueAssessmentComparisonOppCostDescription,
	marketValueAssessmentComparisonSeniroityDescription,
	marketValueAssessmentComparisonValueDescription,
	marketValueAssessmentComparsionGrowthDescription,
} from "../Util/ChartDescriptions";
import "../Style/style.css";

Chart.register(...registerables);

const ChartSection = ({
	title,
	id,
	chartData,
	tableData,
	ranking,
	maxMarketValue,
	description,
}) => {
	const chartRef = useRef(null);
	const [tableColumns] = useState([
		{ field: "clubName", headerName: "Club" },
		{ field: "Rank", headerName: "Rank" },
	]);

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			x: {
				type: "linear",
				position: "bottom",
				title: {
					display: false,
				},
				min: 0,
				max: maxMarketValue,
				ticks: {
					font: {
						size: 15,
					},
				},
			},
			y: {
				display: false,
				min: -0.5,
				max: 0.5,
			},
		},
		plugins: {
			annotation: {
				annotations: {
					line1: {
						type: "line",
						yMin: 0,
						yMax: 0,
						xMin: 0,
						xMax: maxMarketValue,
						borderColor: "grey",
						borderWidth: 0.1,
					},
				},
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const dataPoint = context.raw;
						return dataPoint.clubName ? `Club: ${dataPoint.clubName}` : "";
					},
				},
			},
			legend: {
				display: false,
			},
		},
		animation: {
			onComplete: function () {
				if (chartRef.current) {
					const base64Image = chartRef.current.toBase64Image();
					exportPdfStore.setMarketValueAssessmentComparisonData(
						base64Image,
						id
					);
				}
			},
		},
	};

	return (
		<>
			<div
				className="chart-container"
				style={{ marginTop: "20px", marginBottom: "20px" }}
			>
				<h3 style={{ fontSize: "24px", fontWeight: "bold" }}>{title}</h3>
				<div>
					<div
						className="description-container"
						style={{ textAlign: "center", width: "40%", display: "flex" }}
					>
						{description}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							padding: "30px",
						}}
					>
						<div style={{ width: "10%", position: "relative", top: "-50px" }}>
							<p
								style={{
									textAlign: "center",
									fontSize: "56px",
									fontWeight: "bold",
									marginBottom: "10px",
								}}
							>
								{" "}
								{ranking}{" "}
							</p>
							<p
								style={{
									textAlign: "center",
									fontSize: "18px",
									position: "absolute",
									bottom: "-25px",
									width: "100%",
								}}
							>
								Rank
							</p>
						</div>

						<div style={{ width: "50%", height: "400px" }}>
							{chartData ? (
								<Scatter data={chartData} options={options} ref={chartRef} />
							) : (
								<p>Loading {title} data...</p>
							)}
						</div>
						<div
							style={{
								width: "25%",
								position: "relative",
								left: "20px",
								top: "-20px",
							}}
						>
							<CustomTable TableData={tableData} TableColumns={tableColumns} />
						</div>
					</div>
					<div
						style={{
							textAlign: "left",
							marginBottom: "10px",
							fontStyle: "italic",
							color: "#666",
						}}
					>
						<UpdateIcon sx={{ mb: -0.4, fontSize: "20px" }} />{" "}
						<span> Chart updates weekly</span>
					</div>
				</div>
			</div>
		</>
	);
};

function calculateRanks(data) {
	const groupedData = data.reduce((acc, item) => {
		if (!acc[item.SeasonName]) {
			acc[item.SeasonName] = [];
		}
		acc[item.SeasonName].push(item);
		return acc;
	}, {});

	const rankGroup = (group, key, rankKey) => {
		group.sort((a, b) => b[key] - a[key]);
		let rank = 1;
		for (let i = 0; i < group.length; i++) {
			if (i > 0 && group[i][key] !== group[i - 1][key]) {
				rank = i + 1;
			}
			group[i][rankKey] = rank;
		}
	};

	for (let season in groupedData) {
		if (groupedData.hasOwnProperty(season)) {
			let group = groupedData[season];
			rankGroup(group, "SumGrowth", "GrowthRank");
			rankGroup(group, "SumValue", "ValueRank");
			rankGroup(group, "SumSeniority", "SeniorityRank");
			rankGroup(group, "SumOppCost", "OppCostRank");
		}
	}

	return Object.values(groupedData).flat();
}

const MarketValueAssessmentComparison = (props) => {
	const [growthChartData, setGrowthChartData] = useState({ datasets: [] });
	const [valueChartData, setValueChartData] = useState({ datasets: [] });
	const [seniorityChartData, setSeniorityChartData] = useState({
		datasets: [],
	});
	const [oppCostChartData, setOppCostChartData] = useState({ datasets: [] });

	const [growthTableData, setGrowthTableData] = useState([]);
	const [valueTableData, setValueTableData] = useState([]);
	const [seniorityTableData, setSeniorityTableData] = useState([]);
	const [oppCostTableData, setOppCostTableData] = useState([]);

	const [GrowthRanking, setGrowthRanking] = useState([]);
	const [ValueRanking, setValueRanking] = useState([]);
	const [SeniorityRanking, setSeniorityRanking] = useState([]);
	const [OppCostRanking, setOppCostRanking] = useState([]);

	const [maxMarketValue, setMaxMarketValue] = useState(0);

	useEffect(() => {
		const chartBackgroundColor = getComputedStyle(
			document.documentElement
		).getPropertyValue("--element-color-3");
		const clubBackgroundColor = getComputedStyle(
			document.documentElement
		).getPropertyValue("--element-color-1");
		const loadData = (data) => {
			data = calculateRanks(data);

			const filteredData = data.filter((item) => item.DoShowClubName);

			if (filteredData.length < 1) {
				return;
			}

			data.sort((a, b) => a.GrowthRank - b.GrowthRank);
			const growthRank = data.slice(0, 5).map((item, index) => ({
				id: `growth-${index}`,
				clubName: item.ClubName,
				Rank: item.GrowthRank,
			}));
			setGrowthTableData(growthRank);
			setGrowthChartData({
				labels: [],
				datasets: [
					{
						label: "Growth",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumGrowth,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Growth",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumGrowth,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const GrowthRanking = filteredData[0].GrowthRank;
			setGrowthRanking(GrowthRanking);

			data.sort((a, b) => a.ValueRank - b.ValueRank);
			const valueRank = data.slice(0, 5).map((item, index) => ({
				id: `value-${index}`,
				clubName: item.ClubName,
				Rank: item.ValueRank,
			}));
			setValueTableData(valueRank);
			setValueChartData({
				labels: [],
				datasets: [
					{
						label: "Value",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumValue,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Value",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumValue,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const ValueRanking = filteredData[0].ValueRank;
			setValueRanking(ValueRanking);

			data.sort((a, b) => a.SeniorityRank - b.SeniorityRank);
			const seniorityRank = data.slice(0, 5).map((item, index) => ({
				id: `seniority-${index}`,
				clubName: item.ClubName,
				Rank: item.SeniorityRank,
			}));
			setSeniorityTableData(seniorityRank);
			setSeniorityChartData({
				labels: [],
				datasets: [
					{
						label: "Seniority",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumSeniority,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Seniority",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumSeniority,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const SeniorityRanking = filteredData[0].SeniorityRank;
			setSeniorityRanking(SeniorityRanking);

			data.sort((a, b) => a.OppCostRank - b.OppCostRank);
			const oppCostRank = data.slice(0, 5).map((item, index) => ({
				id: `oppCost-${index}`,
				clubName: item.ClubName,
				Rank: item.OppCostRank,
			}));
			setOppCostTableData(oppCostRank);
			setOppCostChartData({
				labels: [],
				datasets: [
					{
						label: "Opportunity Cost",
						data: data
							.filter((item) => item.DoShowClubName)
							.map((item) => ({
								x: item.SumOppCost,
								y: 0,
								clubName: item.ClubName,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: clubBackgroundColor,
					},
					{
						label: "Opportunity Cost",
						data: data
							.filter((item) => !item.DoShowClubName)
							.map((item) => ({
								x: item.SumOppCost,
								y: 0,
								clubName: null,
								backgroundColor: chartBackgroundColor,
							})),
						pointRadius: 5,
						pointHoverRadius: 7,
						backgroundColor: chartBackgroundColor,
					},
				],
			});
			const OppCostRanking = filteredData[0].OppCostRank;
			setOppCostRanking(OppCostRanking);

			var maxMarketValue = Math.max(
				...data.map((item) => item.SumValue),
				...data.map((item) => item.SumGrowth),
				...data.map((item) => item.SumSeniority),
				...data.map((item) => item.SumOppCost)
			);
			setMaxMarketValue(
				maxMarketValue + (1000000 - (maxMarketValue % 1000000))
			);
		};
		loadData(props.data);
	}, [props.data]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
			}}
		>
			<div className="description-container" style={{ fontWeight: "600" }}>
				The graphs below display the absolute average market values for each
				category, including teams from the same league.
			</div>
			<ChartSection
				title="Growth"
				id="Growth"
				chartData={growthChartData}
				tableData={growthTableData}
				ranking={GrowthRanking}
				maxMarketValue={maxMarketValue}
				description={marketValueAssessmentComparsionGrowthDescription}
			/>
			<ChartSection
				title="Value"
				id="Value"
				chartData={valueChartData}
				tableData={valueTableData}
				ranking={ValueRanking}
				maxMarketValue={maxMarketValue}
				description={marketValueAssessmentComparisonValueDescription}
			/>
			<ChartSection
				title="Seniority"
				id="Seniority"
				chartData={seniorityChartData}
				tableData={seniorityTableData}
				ranking={SeniorityRanking}
				maxMarketValue={maxMarketValue}
				description={marketValueAssessmentComparisonSeniroityDescription}
			/>
			<ChartSection
				title="Opportunity Cost"
				id="Opportunity Cost"
				chartData={oppCostChartData}
				tableData={oppCostTableData}
				ranking={OppCostRanking}
				maxMarketValue={maxMarketValue}
				description={marketValueAssessmentComparisonOppCostDescription}
			/>
		</div>
	);
};

export default MarketValueAssessmentComparison;
