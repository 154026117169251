import { createAuth0Client } from "@auth0/auth0-spa-js";
import { routes } from "./routes";

export default class Auth0Service {
	auth0Client = null;

	async init() {
		const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
		const DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
		const SERVER = process.env.REACT_APP_SERVER;
		this.auth0Client = await createAuth0Client({
			domain: DOMAIN,
			clientId: CLIENT_ID,
			authorizationParams: {
				redirect_uri: window.location.origin + routes.am.clubSummary,
				audience: SERVER,
				scope: "openid profile email offline_access",
			},
			cacheLocation: "localstorage",
			useRefreshTokens: true,
			useRefreshTokensFallback: false,
		});
	}

	async getToken() {
		if (!this.auth0Client) {
			await this.init();
		}
		try {
			return await this.auth0Client.getTokenSilently();
		} catch (error) {
			if (
				error.error === "login_required" ||
				error.error === "consent_required"
			) {
				await this.auth0Client.loginWithRedirect({
					appState: { targetUrl: window.location.pathname },
				});
			} else {
				console.error("Error getting token silently:", error);
				throw error;
			}
		}
	}

	async getUser() {
		if (!this.auth0Client) {
			await this.init();
		}
		return this.auth0Client.getUser();
	}
}

export const auth0Service = new Auth0Service();
