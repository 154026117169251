import { styled } from "@mui/material";
import { Element } from "react-scroll";

export const LandingPageElement = styled(Element)(({ theme, light_bg }) => ({
	backgroundColor: light_bg
		? theme.palette.background.main
		: theme.palette.primary.main,
	color: light_bg ? theme.palette.primary.main : theme.palette.background.main,
	padding: theme.spacing(2, 2, 4),
	minHeight: "75vh",
	display: "flex",
	alignItems: "center",

	[theme.breakpoints.up("md")]: {
		padding: theme.spacing(2, 3, 6),
	},
	[theme.breakpoints.up("xl")]: {
		padding: theme.spacing(2, 5, 8),
	},
}));

export const InnerWrapper = styled("section")({
	maxWidth: "1800px",
	margin: "0 auto",
});
