import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import "chart.js/auto";
import "../Style/am_style.css";

const CustomSlider = styled(Slider)(({ theme }) => ({
	color: "#23374C",
	height: 5,
	padding: "13px 0",
	"& .MuiSlider-thumb": {
		height: 20,
		width: 20,
		backgroundColor: "#4A6877",
		border: "1px solid currentColor",
		"&:hover": {
			boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
		},
	},
	"& .MuiSlider-track": {
		height: 3,
	},
	"& .MuiSlider-rail": {
		color: theme.palette.mode === "dark" ? "#23374C" : "#4A6877",
		opacity: theme.palette.mode === "dark" ? undefined : 1,
		height: 3,
	},
	"& .MuiSlider-markLabel": {
		fontFamily: "Montserrat, sans-serif",
		fontSize: "20px",
	},
	"& .MuiSlider-mark": {
		backgroundColor: "#4A6877",
		height: 8,
		width: 8,
		"&.MuiSlider-markActive": {
			opacity: 1,
			backgroundColor: "currentColor",
		},
	},
}));

export default CustomSlider;
