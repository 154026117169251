import React, { useEffect, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
	Box,
	CircularProgress,
	IconButton,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { auth0Service } from "../Util/Auth0Client";
import "../Style/am_style.css";

const StyledTableHeadTypography = styled(Typography)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	color: "#ffffff",
	fontSize: "16px",
	fontWeight: "bold",
	backgroundColor: ".element-color-4",
	textAlign: "center",
	cursor: "pointer",
}));

const StyledTableRowTypography = styled(Typography)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	fontSize: "14px",
}));

const CustomTable = ({ TableData, TableColumns }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(TableData);
	}, [TableData, TableColumns]);

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");

	const handleSort = (column) => {
		const isAsc = orderBy === column && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(column);
		const sortedData = [...data].sort((a, b) => {
			if (a[column] < b[column]) return isAsc ? -1 : 1;
			if (a[column] > b[column]) return isAsc ? 1 : -1;
			return 0;
		});
		setData(sortedData);
	};

	const downloadInvoice = async (invoiceId) => {
		try {
			const token = await auth0Service.getToken();
			const header = {
				Authorization: `Bearer ${token}`,
			};
			const body = JSON.stringify({ chargebeeInvoiceId: invoiceId });
			const response = await fetch("/api/downloadinvoice", {
				method: "POST",
				body: body,
				headers: header,
				credentials: "include",
			});
			const downloadURL = await response.json();
			window.location.href = downloadURL;
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		} catch (error) {
			console.log("Something went wrong", error);
		}
	};

	return (
		<Box>
			{data.length > 0 ? (
				<TableContainer
					component={Paper}
					sx={{ overflowX: "auto", width: "100%" }}
				>
					<Table>
						<TableHead>
							<TableRow>
								{TableColumns.map((column) => (
									<TableCell
										key={column.field}
										onClick={() => handleSort(column.field)}
									>
										<StyledTableHeadTypography>
											{column.headerName}{" "}
											{orderBy === column.field
												? order === "asc"
													? "↑"
													: "↓"
												: ""}
										</StyledTableHeadTypography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row, rowIndex) => (
								<TableRow key={rowIndex}>
									{TableColumns.map((column) => (
										<TableCell
											key={column.field}
											sx={{ alignItems: "center", textAlign: "center" }}
										>
											{column.isDownloadInvoice ? (
												<IconButton
													onClick={() => downloadInvoice(row[column.field])}
												>
													<FileDownloadIcon style={{ color: "#23374C" }} />
												</IconButton>
											) : (
												<StyledTableRowTypography>
													{row[column.field]}
												</StyledTableRowTypography>
											)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</Box>
	);
};

export default CustomTable;
