import React from "react";
import { Box, Typography } from "@mui/material";

const VerifyEmail = () => {
	return (
		<div>
			<Box
				component="img"
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					margin: "0 auto",
					textAlign: "center",
					position: "relative",
					marginTop: "200px",
					marginBottom: "50px",
					height: 233,
					width: 350,
					maxHeight: { xs: 233, md: 167 },
					maxWidth: { xs: 350, md: 250 },
				}}
				alt="The house from the offer."
				src="https://storage.googleapis.com/assistant-manager-style/Goalunit%20Navy%20Horisontal.svg"
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					maxWidth: "800px",
					width: "150%", // Take full width of the container
					margin: "0 auto",
					textAlign: "center",
					padding: "1rem",
					position: "relative",
				}}
			>
				<Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
					Email Verification Required
				</Typography>
				<Typography variant="body1" paragraph sx={{ mb: 1 }}>
					To get access to Assistant Manager, please verify your account via the
					email we sent. If you haven't received the verification email, contact
					us at info@goalunit.com.
				</Typography>
			</Box>
		</div>
	);
};

export default VerifyEmail;
