import React from "react";
import { CircularProgress } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import { dashboardStore } from "../State/DashboardStore";
import Dashboard from "./Dashboard";
import Datapage from "./Datapage";
import Performance from "./Performance";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	fontSize: "20px",
	fontFamily: "Montserrat, sans-serif",
	color: "#23374C",
	"&.Mui-selected": {
		color: "#23374C",
	},
	"&:hover": {
		color: "#23374C",
	},
}));

// TODO: Här måste vi kolla entitlements.

const ClubOverview = observer(() => {
	return (
		<div>
			<div
				style={{
					padding: "30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ToggleButtonGroup
					color="primary"
					value={dashboardStore.getDashboardType()}
					size="large"
					exclusive
					onChange={dashboardStore.handleDashboardTypeChange}
					aria-label="Platform"
				>
					<CustomToggleButton value="dashboard">Dashboard</CustomToggleButton>
					<CustomToggleButton value="report">Report</CustomToggleButton>
					<CustomToggleButton value="performance">
						Performance
					</CustomToggleButton>
				</ToggleButtonGroup>
			</div>
			<div
				className="separator"
				style={{
					marginTop: "0px",
					marginBottom: "10px",
					borderTop: "2px solid rgb(11, 57, 84, 0.3)",
				}}
			></div>
			{!dashboardStore.isLoading ? (
				dashboardStore.getDashboardType() === "dashboard" ? (
					<Dashboard data={dashboardStore.data} />
				) : dashboardStore.getDashboardType() === "report" ? (
					<Datapage data={dashboardStore.data} />
				) : dashboardStore.getDashboardType() === "performance" ? (
					<Performance data={dashboardStore.data} />
				) : null
			) : (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<CircularProgress />
				</div>
			)}
		</div>
	);
});

export default ClubOverview;
