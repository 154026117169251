import React from "react";
import { Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { profileStore } from "../../State/ProfileStore";
import "./BlurredOverlay.css";
import { routes } from "../../Util/routes";

const CustomTypography = styled(Typography)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
}));

const CustomButton = styled(Button)(({ theme }) => ({
	fontFamily: "Montserrat, sans-serif",
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	backgroundColor: "#1A5F7A",
	"&:hover": {
		backgroundColor: "#23374C",
	},
}));

const BlurredOverlay = () => {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		profileStore.setCurrentTabIndex(0);
		navigate(routes.am.profile);
	};

	return (
		<div className="component-wrapper">
			<div className="blurred-overlay">
				<CustomTypography
					variant="h4"
					gutterBottom
					sx={{ mb: 5, fontWeight: "bold" }}
				>
					Access Denied
				</CustomTypography>
				<CustomTypography variant="h6" gutterBottom sx={{ mb: 5 }}>
					You currently don't have an active subscription to view this page.
					<br />
					Please click the button below to subscribe.
				</CustomTypography>
				<CustomButton variant="contained" onClick={handleButtonClick}>
					Create Subscription
				</CustomButton>
			</div>
		</div>
	);
};

export default BlurredOverlay;
