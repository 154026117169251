import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./Dashboard.css";
import "../Style/am_style.css";

Chart.register(...registerables);
const chartOptions = {
	scales: {
		x: {
			display: false,
			grid: {
				lineWidth: 2,
			},
			border: {
				width: 2,
			},
		},
		y: {
			grid: {
				lineWidth: 2,
			},
			border: {
				width: 2,
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem) {
					return tooltipItem.yLabel;
				},
			},
		},
		waterMarkPlugin: false,
	},
};

const backgroundcolorsNotSelected = [
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-1"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-2"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-3"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-4"
	),
].map((color) => {
	const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
	return rgbaColor;
});

const backgroundcolorsSelected = [
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-1"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-2"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-3"
	),
	getComputedStyle(document.documentElement).getPropertyValue(
		"--element-color-5"
	),
].map((color) => {
	const rgbaColor = color.replace(")", ", 0.5)").replace("rgb", "rgba");
	return rgbaColor;
});

const ClubKPIs = ({ cardsKPIData, selectedCard, onCardClick }) => {
	const [powerRank, setPowerRank] = useState(null);
	const [localPowerRank, setLocalPowerRank] = useState(null);
	const [areaName, setAreaName] = useState(null);
	const [powerRankSeasonName, setPowerRankSeasonName] = useState(null);
	const [squadValue, setSquadValue] = useState(null);
	const [squadValuePercentage, setSquadValuePercentage] = useState(null);
	const [transferKPI, setTransferKPI] = useState(null);
	const [transferKPIChange, setTransferKPIChange] = useState(null);
	const [transferKPIRiskLevel, setTransferKPIRiskLevel] = useState(null);
	const [PPTDB, setPPTDB] = useState(null);
	const [GVSO, setGVSO] = useState({
		datasets: [{ data: [] }],
		labels: [],
	});
	const chartRef = useRef(null);

	const extractData = (data) => {
		if (!data || Object.keys(data).length === 0) return;

		// Process and update KPI-related state variables
		const KPIData = data.KPI || [];
		const currentTransferKPI = KPIData.filter((d) => d.RowNo === 1);
		const previousTransferKPI = KPIData.filter((d) => d.RowNo === 2);

		const sumCurrentTransferKPI = currentTransferKPI.reduce(
			(total, item) => total + item.NewTransferKpi,
			0
		);
		const sumPreviousTransferKPI = previousTransferKPI.reduce(
			(total, item) => total + item.NewTransferKpi,
			0
		);

		const KPIRatio = (
			100 *
			(sumCurrentTransferKPI / sumPreviousTransferKPI - 1)
		).toFixed(0);

		setTransferKPI(sumCurrentTransferKPI.toFixed(1));
		setTransferKPIChange(KPIRatio);

		// Process TKR
		const numberOfRows = data.TKR.length || 0;
		let transferLevel;
		if (numberOfRows > 4) {
			transferLevel = "Low";
		} else if (numberOfRows >= 3 && numberOfRows <= 4) {
			transferLevel = "Medium";
		} else if (numberOfRows < 3 && numberOfRows !== 0) {
			transferLevel = "High";
		} else {
			transferLevel = "Not available";
		}
		setTransferKPIRiskLevel(transferLevel);

		// Process MCV (Squad Value)
		const SquadValue = (data.MCV || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const CurrentSeasonSquadValue = SquadValue[0] || {};
		const EUR = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "EUR",
		});
		setSquadValue(EUR.format(CurrentSeasonSquadValue.ClubMarketValueSum || 0));
		setSquadValuePercentage(
			CurrentSeasonSquadValue.DomesticPercentage?.toFixed(0) || "0"
		);

		// Process PR (Power Rank)
		const PowerRank = (data.PR || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const CurrentPowerRank = PowerRank[0] || {};
		setPowerRank(CurrentPowerRank.PowerRank || null);
		setAreaName(CurrentPowerRank.AreaName || null);
		setLocalPowerRank(CurrentPowerRank.LocalPowerRank || null);
		setPowerRankSeasonName(CurrentPowerRank.SeasonName || null);

		// Process PTD (Play Time Distribution)
		const playTimeDistributionData = (data.PTD || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const currentSeasonName = playTimeDistributionData[0]?.SeasonName || null;
		const currentSeason = playTimeDistributionData.filter(
			(d) => d.SeasonName === currentSeasonName
		);
		const playedMatches = Math.max(...currentSeason.map((d) => d.MatchNo), 0);
		const latestStats = currentSeason.filter(
			(d) => d.MatchNo === playedMatches
		);

		let ageCategories = new Set();
		latestStats.forEach((item) => ageCategories.add(item.AgeCategory));
		const ageCategoriesOrder = Array.from(ageCategories).sort((a, b) => {
			const getLowestNumber = (str) => parseInt(str.match(/\d+/)?.[0] || "0");
			return getLowestNumber(a) - getLowestNumber(b);
		});

		const filteredData = latestStats.filter((d) =>
			ageCategoriesOrder.slice(0, 2).includes(d.AgeCategory)
		);
		setPPTDB(
			filteredData
				.reduce((total, item) => total + item.PartOfPlayingTime, 0)
				.toFixed(0)
		);

		// Process PMA
		const currentsGVSO = (data.PMA || [])
			.slice()
			.sort((a, b) => b.SeasonName.localeCompare(a.SeasonName));
		const currentSeasonGVSO = currentsGVSO[0] || {};

		setGVSO({
			datasets: [
				{
					data: [
						currentSeasonGVSO.GrowthSum || 0,
						currentSeasonGVSO.ValueSum || 0,
						currentSeasonGVSO.SenioritySum || 0,
						currentSeasonGVSO.OppCostSum || 0,
					],
					backgroundColor: backgroundcolorsNotSelected,
				},
			],
			labels: ["Growth", "Value", "Seniority", "Opportunity Cost"],
		});
	};

	useEffect(() => {
		if (cardsKPIData) {
			extractData(cardsKPIData);
		}
	}, [cardsKPIData]);

	useEffect(() => {
		let updatedChartData = {
			...GVSO,
			datasets: [
				{
					...GVSO.datasets[0],
					backgroundColor:
						selectedCard === "GVSO"
							? backgroundcolorsSelected
							: backgroundcolorsNotSelected,
				},
			],
		};
		chartRef.current.options.scales.x.ticks.color =
			selectedCard === "GVSO" ? "white" : "black";
		chartRef.current.options.scales.x.grid.color =
			selectedCard === "GVSO"
				? "rgba(255, 255, 255, 0.1)"
				: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.x.border.color =
			selectedCard === "GVSO"
				? "rgba(255, 255, 255, 0.1)"
				: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.y.ticks.color =
			selectedCard === "GVSO" ? "white" : "black";
		chartRef.current.options.scales.y.grid.color =
			selectedCard === "GVSO"
				? "rgba(255, 255, 255, 0.1)"
				: "rgba(0, 0, 0, 0.1)";
		chartRef.current.options.scales.y.border.color =
			selectedCard === "GVSO"
				? "rgba(255, 255, 255, 0.1)"
				: "rgba(0, 0, 0, 0.1)";
		setGVSO(updatedChartData);
	}, [selectedCard]);

	return (
		<div className="dashboard-background">
			<div className="dashboard-KPI-container">
				<div
					className={`dashboard-KPI-card ${selectedCard === "transferKPI" ? "selected" : ""}`}
					onClick={() => onCardClick("transferKPI")}
				>
					<div className="dashboard-KPI-card-title">Transfer KPI</div>
					<div className="dashboard-KPI-card-value">{transferKPI}</div>
					<div className="dashboard-KPI-card-subtitle">
						<span style={{ color: transferKPIChange < 0 ? "red" : "green" }}>
							{transferKPIChange}%
						</span>{" "}
						since last update
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCard === "squadValue" ? "selected" : ""}`}
					onClick={() => onCardClick("squadValue")}
				>
					<div className="dashboard-KPI-card-title">Average Squad value</div>
					<div className="dashboard-KPI-card-value">{squadValue}</div>
					<div className="dashboard-KPI-card-subtitle">
						{squadValuePercentage}% of {areaName} market value
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCard === "powerRank" ? "selected" : ""}`}
					onClick={() => onCardClick("powerRank")}
				>
					<div className="dashboard-KPI-card-title">
						Power rank {powerRankSeasonName}
					</div>
					<div className="dashboard-KPI-card-value">{powerRank}</div>
					<div className="dashboard-KPI-card-subtitle">
						Rank {localPowerRank} in {areaName}
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCard === "transferKPIRiskLevel" ? "selected" : ""}`}
					onClick={() => onCardClick("transferKPIRiskLevel")}
				>
					<div className="beta-tag">Beta</div>
					<div className="dashboard-KPI-card-title">Transfer KPI Risk</div>
					<div className="dashboard-KPI-card-value">
						<span
							style={{
								color:
									transferKPIRiskLevel === "High"
										? "red"
										: transferKPIRiskLevel === "Medium"
											? "orange"
											: "green",
							}}
						>
							{transferKPIRiskLevel ? transferKPIRiskLevel : "Not available"}
						</span>
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCard === "playTimeDistribution" ? "selected" : ""}`}
					onClick={() => onCardClick("playTimeDistribution")}
				>
					<div className="dashboard-KPI-card-title">Part of playing time</div>
					<div className="dashboard-KPI-card-value">
						<span style={{ color: PPTDB < 50 ? "inherit" : "green" }}>
							{PPTDB} %
						</span>
					</div>
					<div className="dashboard-KPI-card-subtitle">
						For players under the age of 24{" "}
					</div>
				</div>

				<div
					className={`dashboard-KPI-card ${selectedCard === "GVSO" ? "selected" : ""}`}
					onClick={() => onCardClick("GVSO")}
				>
					<div className="dashboard-KPI-card-title">
						Share of MV per category
					</div>
					<div className="dashboard-KPI-card-value">
						<Bar ref={chartRef} data={GVSO} options={chartOptions} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClubKPIs;
