export const transferKpiRiskDescription = `Risk classification is based on how the Transfer KPI is distributed in the squad. 
                                           If few players account for a big part of the total Transfer KPI, your risk is higher, 
                                           and vice versa.`;

export const transferKpiComparsionDescription = `Goalunit Transfer KPI for the men’s first team in recent seasons, as well as the 
                                                 average in the league the club played in `;

export const transferKpiDescription = `Size and color of each player's marker depends on the player's Transfer KPI.
                                     The bigger and greener the color, the higher the Transfer KPI.
                                      The KPI is a function of age index, remaining months on contract, position index, share of playing time, and share of points relative to position.
                                      The Transfer KPI shows each players potential for generating transfer revenue.`;

export const powerRankDescrption = `The graph shows Goalunits Power Rank for all clubs in the data base, i.e. ranks all clubs based 
                                    on their sporting competitiveness. The power rank is calculated based on ELO-methodology, 
                                    a commonly accepted ranking algorithm within, for example, chess.`;

export const playTimeDistributionDescription = `The graph shows share of playing allocated by player age category per year 
                                                (accumulated for current year). Age categories are 20 years and younger, 
                                                21-23, 24-28, 29 and older.`;

export const marketValueComparisonDescription = `The graph shows the average total market value for the men’s first team per year 
                                                 (bars, mEur), as well as the market value as a share of the total league market 
                                                 value (Line). The team market value is updated and averaged on a monthly basis.`;

export const marketValueAssessmentComparsionGrowthDescription = `Young talents, 25 and under, playing less than 50% of the time. High potential 
                                                                 for development and future impact.`;

export const marketValueAssessmentComparisonValueDescription = `Rising stars, 25 and under, playing more than 50% of the time. Proven contributors 
                                                                with significant impact.`;

export const marketValueAssessmentComparisonSeniroityDescription = `Experienced players, 26 and older, playing more than 50% of the time. 
                                                                    Key contributors with leadership and stability.`;

export const marketValueAssessmentComparisonOppCostDescription = `Veterans, 26 and older, playing less than 50% of the time. 
                                                                  Offer valuable skills and insights for specific situations.`;
