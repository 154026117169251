import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable, stopPersisting } from "mobx-persist-store";
import { auth0Service } from "../Util/Auth0Client";

export default class CountriesStore {
	data = [];
	isLoading = false;
	error = null;
	countriesType = "Countries";
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: "CountriesStore",
			properties: ["data"],
			storage: window.localStorage,
		});
	}
	fetchData = async () => {
		this.setLoading(true);
		this.setError(null);

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch("/api/countries", {
				credentials: "include",
				headers: header,
			});
			const countriesData = await response.json();
			runInAction(() => {
				this.setData(countriesData);
			});
		} catch (error) {
			runInAction(() => {
				this.setError(error.message);
			});
		} finally {
			runInAction(() => {
				this.setLoading(false);
			});
		}
	};

	setData(newData) {
		this.data = newData;
	}

	setLoading(loading) {
		this.isLoading = loading;
	}

	setError(error) {
		this.error = error;
	}

	getCountriesType = () => {
		return this.countriesType;
	};

	stopPersist() {
		stopPersisting(this);
	}
}

export const countriesStore = new CountriesStore();
