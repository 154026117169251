export const routes = {
	start: {
		base: "/",
		home: "home",
		am: "am",
		services: "services",
		about: "about",
		contact: "contact",
	},
	am: {
		base: "/assistant-manager",
		clubSummary: "/assistant-manager/clubsummary",
		squadPage: "/assistant-manager/squadpage",
		profile: "/assistant-manager/profile",
		termsAndConditions: "/assistant-manager/terms-and-conditions",
		subscriptionConfirmation: "/subscriptionConfirmation",
		logOut: "/logout",
	},
};
