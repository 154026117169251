import React from "react";
import { styled, Typography } from "@mui/material";
import { color } from "../Style/theme";
import { InnerWrapper, LandingPageElement } from "./components";

const ServiceList = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	marginBottom: theme.spacing(4),

	[theme.breakpoints.up("lg")]: {
		flexDirection: "row",
	},
}));

const ServiceItem = styled("div")(({ theme }) => ({
	borderBottom: "3px solid " + theme.palette.primary.light,
	color: theme.palette.background.main,
	padding: theme.spacing(2),
	flex: 1,

	"&:last-child": {
		borderBottom: "none",
	},

	[theme.breakpoints.up("md")]: {
		padding: theme.spacing(4),
	},

	[theme.breakpoints.up("lg")]: {
		borderBottom: "none",
		borderRight: "3px solid " + theme.palette.primary.light,
		"&:last-child": {
			borderRight: "none",
		},
	},
}));

function Services() {
	const sections = [
		{
			title: "Strategy & Operations",
			body: "Optimizing your sporting strategy and follow-up through data-driven KPI:s and improved decision material, creating transparency and improving the control of your results on and off the pitch.",
		},
		{
			title: "Assistant Manager",
			body: "Our cutting edge platform automates your strategic follow-up and enables you to allocate time to make decisions instead of compiling presentations.",
		},
		{
			title: "Financing",
			body: "Goalunit provides external financing to football clubs withm mechanisms leveraging long-term decisions.",
		},
	];
	return (
		<LandingPageElement name="services" id="services">
			<InnerWrapper>
				<Typography
					component="h2"
					variant="h2"
					sx={{ color: color.background.main }}
				>
					Services
				</Typography>

				<ServiceList>
					{sections.map((section) => (
						<ServiceItem key={section.title}>
							<Typography variant="h3" sx={{ color: color.background.main }}>
								{section.title}
							</Typography>
							<Typography variant="body1" sx={{ color: color.background.main }}>
								{section.body}
							</Typography>
						</ServiceItem>
					))}
				</ServiceList>
			</InnerWrapper>
		</LandingPageElement>
	);
}

export default Services;
