import React, { useState } from "react";
import { Button, styled, Tooltip } from "@mui/material";
import {
	Document,
	Font,
	Image,
	Page,
	pdf,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import logo from "../Logos/GoalunitLogo_NavyHorisontal.png";
import logoCover from "../Logos/GoalunitLogo_NavyHorisontalCover.png";
import { exportPdfStore } from "../State/ExportPdfStore";
import { impersonationStore } from "../State/ImpersonationStore";
import montserratBold from "../Style/Montserrat-Bold.ttf";
// Import Montserrat fonts from local files
import montserratRegular from "../Style/Montserrat-Regular.ttf";
import {
	marketValueAssessmentComparisonOppCostDescription,
	marketValueAssessmentComparisonSeniroityDescription,
	marketValueAssessmentComparisonValueDescription,
	marketValueAssessmentComparsionGrowthDescription,
	marketValueComparisonDescription,
	playTimeDistributionDescription,
	powerRankDescrption,
	transferKpiComparsionDescription,
	transferKpiDescription,
	transferKpiRiskDescription,
} from "../Util/ChartDescriptions";

// Chart descriptions in single line
const marketValueComparisonDescriptionSingleLine =
	marketValueComparisonDescription.replace(/\n\s+/g, " ").trim();
const powerRankDescrptionSingleLine = powerRankDescrption
	.replace(/\n\s+/g, " ")
	.trim();
const transferKpiDescriptionSingleLine = transferKpiDescription
	.replace(/\n\s+/g, " ")
	.trim();
const transferKpiRiskDescriptionSingleLine = transferKpiRiskDescription
	.replace(/\n\s+/g, " ")
	.trim();
const playTimeDistributionDescriptionSingleLine =
	playTimeDistributionDescription.replace(/\n\s+/g, " ").trim();
const transferKpiComparsionDescriptionSingleLine =
	transferKpiComparsionDescription.replace(/\n\s+/g, " ").trim();
const marketValueAssessmentComparsionGrowthDescriptionSingleLine =
	marketValueAssessmentComparsionGrowthDescription
		.replace(/\n\s+/g, " ")
		.trim();
const marketValueAssessmentComparisonValueDescriptionSingleLine =
	marketValueAssessmentComparisonValueDescription.replace(/\n\s+/g, " ").trim();
const marketValueAssessmentComparisonSeniroityDescriptionSingleLine =
	marketValueAssessmentComparisonSeniroityDescription
		.replace(/\n\s+/g, " ")
		.trim();
const marketValueAssessmentComparisonOppCostDescriptionSingleLine =
	marketValueAssessmentComparisonOppCostDescription
		.replace(/\n\s+/g, " ")
		.trim();

// Tooltip title
const toolTipTitle =
	"Make sure to always maximize your browser window and refresh the page before generating the PDF report";

// Register Montserrat fonts
Font.register({
	family: "Montserrat",
	fonts: [
		{ src: montserratRegular, fontWeight: "normal" },
		{ src: montserratBold, fontWeight: "bold" },
	],
});

// Define styles for the PDF
const styles = StyleSheet.create({
	coverPage: {
		backgroundColor: "#FFFFFF",
		position: "relative",
		height: "100%",
	},
	coverContent: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: 40, // Increased padding for better spacing
	},
	coverLogo: {
		width: 400, // Slightly reduced for better proportion
		height: 100,
		marginBottom: 50, // Increased margin for better spacing
	},
	coverTitle: {
		fontFamily: "Montserrat",
		fontWeight: "bold",
		fontSize: 36, // Increased font size
		textAlign: "center",
		marginBottom: 20, // Increased margin
	},
	coverSubtitle: {
		fontFamily: "Montserrat",
		fontSize: 20, // Increased font size
		textAlign: "center",
	},
	page: {
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		padding: 20,
	},
	header: {
		position: "absolute",
		top: 10,
		left: 0,
		right: 0,
		paddingHorizontal: 20,
		paddingBottom: 5,
		borderBottomWidth: 0.5,
		borderBottomColor: "#000",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		zIndex: 1, // Ensure header is on top
	},
	logo: {
		width: 80,
		height: 20,
	},
	contentContainer: {
		marginTop: 50, // Adjust this value to ensure content starts below the header
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		fontFamily: "Montserrat",
		fontWeight: "bold",
		fontSize: 30,
		textAlign: "center",
		marginBottom: 10,
	},
	imageContainer: {
		width: "90%",
		height: "80%",
		justifyContent: "center",
		alignItems: "center",
	},
	image: {
		objectFit: "contain",
		maxWidth: "100%",
		maxHeight: "100%",
	},
	description: {
		fontFamily: "Montserrat",
		fontWeight: "normal",
		fontSize: 12,
		textAlign: "center",
		marginTop: 10,
	},
	bold: {
		fontFamily: "Montserrat",
		fontSize: 14,
		fontWeight: "bold",
		textAlign: "center",
	},
});

const CoverPage = ({ title, subtitle }) => (
	<Page size="A4" style={styles.coverPage}>
		<View style={styles.coverContent}>
			<Image style={styles.coverLogo} src={logoCover} />
			<Text style={styles.coverTitle}>{title}</Text>
			<Text style={styles.coverSubtitle}>{subtitle}</Text>
		</View>
	</Page>
);
// A reusable component for each page in the PDF
const GraphPage = ({ title, imageData, descriptionParts }) => (
	<Page size="A4" style={styles.page}>
		<View style={styles.header} fixed>
			<Image style={styles.logo} src={logo} />
		</View>
		<View style={styles.contentContainer}>
			<Text style={styles.title}>{title}</Text>
			<View style={styles.imageContainer}>
				<Image style={styles.image} src={imageData} />
			</View>
			<View>
				{descriptionParts.map((line, index) => (
					<View key={index} style={styles.description}>
						<Text style={styles.bold}>{line.label}</Text>
						<Text>{line.text}</Text>
					</View>
				))}
			</View>
		</View>
	</Page>
);

// The complete PDF document structure
const PDFDocument = () => {
	const clubName = impersonationStore.extractClubName();
	const title = clubName ? `${clubName} Report` : "";
	const date = new Date();
	const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
	const subtitle = "Created on " + formattedDate;
	return (
		<Document>
			<CoverPage title={title} subtitle={subtitle} />
			{exportPdfStore.powerrankData && (
				<GraphPage
					title="Power Rank"
					imageData={exportPdfStore.powerrankData}
					descriptionParts={[
						{
							label: "",
							text: powerRankDescrptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.transferKPIData && (
				<GraphPage
					title="Transfer KPI"
					imageData={exportPdfStore.transferKPIData}
					descriptionParts={[
						{
							label: "",
							text: transferKpiDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.transferKPIRiskData && (
				<GraphPage
					title="Transfer KPI Risk"
					imageData={exportPdfStore.transferKPIRiskData}
					descriptionParts={[
						{
							label: "",
							text: transferKpiRiskDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.playerMarketValueAssessmentData && (
				<GraphPage
					title="Market value share per category"
					imageData={exportPdfStore.playerMarketValueAssessmentData}
					descriptionParts={[
						{
							label: "",
							text: "The graph shows the share of market value per player category within the club's squad.",
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueComparisonData && (
				<GraphPage
					title="Average Squad value"
					imageData={exportPdfStore.marketValueComparisonData}
					descriptionParts={[
						{
							label: "",
							text: marketValueComparisonDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.playTimeDistributionData && (
				<GraphPage
					title="Age Distribution"
					imageData={exportPdfStore.playTimeDistributionData}
					descriptionParts={[
						{
							label: "",
							text: playTimeDistributionDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.transferKPIComparisonData && (
				<GraphPage
					title="Transfer KPI Comparison"
					imageData={exportPdfStore.transferKPIComparisonData}
					descriptionParts={[
						{
							label: "",
							text: transferKpiComparsionDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueComparisonData && (
				<GraphPage
					title="Market Value Assessment Comparison"
					imageData={exportPdfStore.marketValueComparisonData}
					descriptionParts={[
						{
							label: "Growth",
							text: "Under 25, less than 50% play time.",
						},
						{
							label: "Value",
							text: "Under 25, more than 50% play time.",
						},
						{
							label: "Seniority",
							text: "26 and older, more than 50% play time.",
						},
						{
							label: "Opportunity Cost",
							text: "26 and older, less than 50% play time.",
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueAssessmentComparisonDataForGrowth && (
				<GraphPage
					title="Market Value Assessment Comparison - Growth"
					imageData={
						exportPdfStore.marketValueAssessmentComparisonDataForGrowth
					}
					descriptionParts={[
						{
							label: "",
							text: marketValueAssessmentComparsionGrowthDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueAssessmentComparisonDataForValue && (
				<GraphPage
					title="Market Value Assessment Comparison - Value"
					imageData={exportPdfStore.marketValueAssessmentComparisonDataForValue}
					descriptionParts={[
						{
							label: "",
							text: marketValueAssessmentComparisonValueDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueAssessmentComparisonDataForSeniority && (
				<GraphPage
					title="Market Value Assessment Comparison - Seniority"
					imageData={
						exportPdfStore.marketValueAssessmentComparisonDataForSeniority
					}
					descriptionParts={[
						{
							label: "",
							text: marketValueAssessmentComparisonSeniroityDescriptionSingleLine,
						},
					]}
				/>
			)}
			{exportPdfStore.marketValueAssessmentComparisonDataForOppCost && (
				<GraphPage
					title="Market Value Assessment Comparison - Opportunity Cost"
					imageData={
						exportPdfStore.marketValueAssessmentComparisonDataForOppCost
					}
					descriptionParts={[
						{
							label: "",
							text: marketValueAssessmentComparisonOppCostDescriptionSingleLine,
						},
					]}
				/>
			)}
		</Document>
	);
};

const PDFDocumentGenerator = async () => {
	return await pdf(<PDFDocument />).toBlob();
};

// Define the custom button with conditional styling
const CustomButton = styled(Button)(({ theme, isLoading }) => ({
	fontFamily: "Montserrat, sans-serif",
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	backgroundColor: isLoading ? "red" : "#1A5F7A",
	"&:hover": {
		backgroundColor: isLoading ? "darkred" : "#23374C",
	},
}));

// Main component
const ExportPDF = () => {
	const [isLoading, setIsLoading] = useState(false);

	const handleExportPDF = async () => {
		setIsLoading(true); // Start loading state
		// Simulate a delay of 4 seconds to ensure all data is ready
		await new Promise((resolve) => setTimeout(resolve, 4000));

		const clubName = impersonationStore.extractClubName();
		const pdfBlob = await PDFDocumentGenerator();
		const url = URL.createObjectURL(pdfBlob);
		const link = document.createElement("a");
		link.href = url;
		const date = new Date();
		const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
		const reportName = clubName
			? `${clubName}-Report-${formattedDate}.pdf`
			: `Report-${formattedDate}.pdf`;
		link.download = reportName;
		link.click();
		setIsLoading(false); // Stop loading state
	};

	return (
		<div>
			<Tooltip title={toolTipTitle}>
				<CustomButton
					variant="contained"
					onClick={handleExportPDF}
					isLoading={isLoading}
				>
					{isLoading ? "Exporting PDF ..." : "Export Report to PDF"}
				</CustomButton>
			</Tooltip>
		</div>
	);
};

export default ExportPDF;
